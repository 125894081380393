<template>
  <div
    class="appClass"
    v-loading.fullscreen.lock="this.$store.state.globalLoading"
    element-loading-text="正在加载,请稍等..."
    element-loading-background="rgba(0, 0, 0, 0.8)"
    element-loading-spinner="el-icon-loading"
  >
    <v-head></v-head>
    <div class="content-box">
      <v-sidebar class="sidebar"></v-sidebar>
      <div :class="$store.state.isCollapse ? 'centreContent2' : 'centreContent1'">
        <v-tags class="tags"></v-tags>
        <transition
          name="move"
          mode="out-in"
        >
          <keep-alive :include="$store.state.componentCacheList">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
//头部组件
import vHead from "../../components/Header";
// 左侧菜单组件
import vSidebar from "../../components/Sidebar";
//多标签页组件
import vTags from "../../components/tags.vue";

export default {
  data() {
    return {};
  },
  components: {
    vHead,
    vSidebar,
    vTags,
  },
  watch: {},
  created() {},
};
</script>
<style scoped>
@media (max-width: 768px) {
  .content-box .sidebar,
  .content-box .tags {
    display: none;
  }
}
</style>