<template>
  <div class="tags" v-if="showTags">
    <ul style="margin: 0; padding-left: 20px">
      <li
        class="tags-li"
        v-for="(item, index) in tagsList"
        :class="{ active: isActive(item.name) }"
        :key="index"
      >
        <router-link :to="item.path" class="tags-li-title">{{
          item.title
        }}</router-link>
        <span class="tags-li-icon" @click="closeTags(index)">
          <i class="el-icon-close"></i>
        </span>
      </li>
    </ul>
    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <img src="@/assets/moreIcon.png" class="moreIcon" />
        <el-dropdown-menu
          size="small"
          slot="dropdown"
          style="margin-top: -15px !important"
        >
          <el-dropdown-item command="other">{{
            $store.state.language == "en" ? "Close Others" : "关闭其他"
          }}</el-dropdown-item>
          <el-dropdown-item command="all">{{
            $store.state.language == "en" ? "Close All" : "关闭所有"
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import bus from "./bus";

export default {
  data() {
    return {
      tagsList: [],
    };
  },
  methods: {
    isActive(path) {
      return path === this.$route.name;
    },
    // 关闭单个标签
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.fullPath &&
          this.$router.replace(item.path);
      } else {
        this.tagsList.push(delItem);
      }
    },
    // 关闭全部标签
    closeAll() {
      this.tagsList = [];
      this.getRoleIndexUrl();
    },
    // 关闭其他标签
    closeOther() {
      this.tagsList = [];
      if (this.$route.meta.title) this.setTags(this.$route);
    },
    /**
     * 根据角色id查询入口页，关闭所有时跳转到入口页
     */
    getRoleIndexUrl() {
      let roleId = this.roleId();
      this.$http.get(`admin/sys-role/baseGetById/${roleId}`).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data && data.indexUrl) {
            if (data.indexUrl == this.$route.path) {
              let route = this.$route;
              this.tagsList.push({
                title: route.meta.title,
                name: route.name,
                path: route.path,
              });
            } else
              this.$router.replace({
                path: data.indexUrl,
              });
          } else this.$router.replace("/");
        }
      });
    },

    roleId() {
      let userObject = this.$store.state.loginUserInfo;
      let roleId = "";
      if (
        userObject.sysRoleList &&
        userObject.sysRoleList.length > 0 &&
        userObject.sysRoleList[0].roleId
      ) {
        roleId = userObject.sysRoleList[0].roleId;
      }
      return roleId || userObject.roleId;
    },
    // 设置标签
    setTags(route) {
      const isExist = this.tagsList.some((item) => {
        return item.name === route.name;
      });
      if (!isExist) {
        if (this.tagsList.length >= 9) {
          this.tagsList.shift();
        }
        this.tagsList.push({
          title: route.meta.title,
          path: route.fullPath,
          name: route.name,
        });
      }
      bus.$emit("tags", this.tagsList);
    },
    handleTags(command) {
      command === "other" ? this.closeOther() : this.closeAll();
    },
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.meta && newValue.meta.title) this.setTags(newValue);
    },
  },
  created() {
    if (this.$route.meta.title) this.setTags(this.$route);
  },
};
</script>


<style scoped>
.tags {
  box-sizing: border-box;
  position: relative;
  height: 54px;
  line-height: 54px;
  overflow: hidden;
  padding-right: 120px;
  z-index: 4;
  margin-bottom: -10px;
  background-color: #f4f4f4;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.tags-li:hover {
  background: #fff;
  opacity: 1;
}
.tags-li {
  box-sizing: border-box;
  float: left;
  margin-right: 5px;
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  color: #333;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  opacity: 0.8;
}

.tags-li.active {
  color: #fff;
  border: 1px solid #409eff;
  background-color: #409eff;
  opacity: 1;
}

.tags-li-title {
  float: left;
  /* max-width: 80px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li.active .tags-li-title {
  color: #fff;
}
.el-icon-close {
  width: 8px;
  height: 8px;
}
.tags-close-box {
  position: absolute;
  right: 20px;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  z-index: 10;
}
.moreIcon {
  margin-top: 10px;
}
.tags-close-box .el-button {
  height: 30px;
  font-size: 14px;
  box-sizing: border-box;
}
</style>
