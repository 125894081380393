<template>
  <div class="header">
    <div class="header-left">
      <span class="role_name">{{
        roleName == "客户" && $store.state.language == "en"
          ? "customer"
          : roleName
      }}</span>
      <button @click="toggleSidebar" class="menu-toggle-btn">
        <i class="el-icon-menu"></i>
      </button>
    </div>
    <div class="header-right">
      <div v-if="qrCode">
        <el-image class="qrCode" :src="qrCode" :preview-src-list="[qrCode]" style="width: 30px; height: 30px" />
      </div>
      <div class="header-user-con">
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <!-- 用户头像 -->
          <div class="user-avator" style="display: flex; align-items: center">
            <span class="el-dropdown-link" style="margin-right: 20px">
              {{ username }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <img v-if="!userImg" src="../assets/default-avatar.png" />
            <!-- 异步加载头像 -->
            <img v-else :src="userImg" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="editPassWord">{{
              $store.state.language == "en" ? "Change Password" : "修改密码"
            }}</el-dropdown-item>
            <el-dropdown-item command="signOutLogin">{{
              $store.state.language == "en" ? "Log Out" : "退出登录"
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <v-sidebar v-if="isSidebarVisible && isMobile" class="sidebar"></v-sidebar>
    <el-dialog
      :title="$store.state.language == 'en' ? 'Change Password' : '修改密码'"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <el-form
        :model="passwordForm"
        :rules="passwordFormRules"
        ref="passwordForm"
        label-position="right"
        label-width="110px"
      >
        <el-form-item label="原密码：" prop="password">
          <el-input type="password" v-model="passwordForm.password"></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input
            type="password"
            v-model="passwordForm.newPassword"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePassword('passwordForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 左侧菜单组件
import vSidebar from "./Sidebar";
export default {
components: {
    vSidebar,
  },
  data() {
    return {
      passwordFormRules: {
        originapasswordlPassWord: [
          { required: true, trigger: "blur", message: "旧密码不能为空！" },
        ],
        newPassword: [
          { required: true, trigger: "blur", message: "新密码不能为空！" },
        ],
      },
      centerDialogVisible: false,
      passwordForm: {},
      isSidebarVisible: false,
    };
  },
  created() {},
  computed: {
    username() {
      let userObject = this.$store.state.loginUserInfo;
      return userObject.nickName || userObject.name;
    },
    roleName() {
      let userObject = this.$store.state.loginUserInfo;
      let roleName = "";
      if (
        userObject.sysRoleList &&
        userObject.sysRoleList.length > 0 &&
        userObject.sysRoleList[0].roleName
      ) {
        let list = userObject.sysRoleList;
        list.forEach((item) => {
          roleName += item.roleName + "、";
        });
        if (roleName.indexOf("、") != -1) roleName = roleName.slice(0, -1);
      }
      return roleName || userObject.roleName;
    },
    userImg() {
      return this.$store.state.loginUserInfo.avatar;
    },
    qrCode() {
      return this.$store.state.loginUserInfo.qrCode || "";
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    //修改密码
    updatePassword(formName) {
      let loginUserInfo = this.$store.state.loginUserInfo;
      this.passwordForm.userId = loginUserInfo.userId;
      if (loginUserInfo.roleId) {
        //不存在用户id，用角色id查询权限
        this.passwordForm.userId = loginUserInfo.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = `admin/sys-user/password`;
          if (this.getUserRoleName() == "客户") url = `user-login/password`;
          this.$http
            .post(url, this.passwordForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "修改密码成功，请重新登录",
                  type: "success",
                });
                this.centerDialogVisible = false;
                this.$logout();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "操作失败，请重试或联系管理员",
                type: "warning",
              });
            });
        }
      });
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "signOutLogin") {
        this.$confirm("确定退出登录吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$logout();
          })
          .catch(() => {
            this.$message({
              message: "取消操作!",
            });
          });
      }
      if (command == "editPassWord") {
        //修改密码
        this.centerDialogVisible = true;
        if (this.$refs.passwordForm !== undefined) {
          this.$refs.passwordForm.resetFields();
        }
      }
    },
  },
};
</script>
<style scoped lang="less">
.avatar-uploader-icon {
  font-size: 14px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  img {
    background-color: #999;
  }
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
  color: #409eff;
}
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  color: #606266;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-left {
    margin-left: 220px;
    font-size: 16px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    .role_name {
      display: none;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 700;
    .user-name {
      margin-left: 10px;
    }

    .user-avator {
      margin-left: 10px;
    }

    .user-avator img {
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }

  .header-user-con {
    display: flex;
    height: 55px;
    align-items: center;
  }

  .btn-bell {
    position: relative;
    cursor: pointer;
  }
  .el-icon-caret-bottom {
    display: none;
  }
}

.el-dropdown-link {
  color: #333;
  cursor: pointer;
  font-size: 16px;
}

.el-dropdown-menu__item {
  text-align: center;
}
.header {
  @media (max-width: 768px) {
    .header-left {
      margin-left: 10px;
      font-size: 14px;
    }
    .header-right {
      padding-right: 10px;
      .user-avator img {
        width: 28px;
        height: 28px;
      }
    }
  }
  .menu-toggle-btn {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    .menu-toggle-btn {
      display: inline-block;
    }
  }
  .sidebar {
    width: 50%;
    position: fixed;
    top: 54px;
    left: 0;
    z-index: 99;
    .logo {
      width: 100px;
    }
    .logoTxt {
      font-size: 14px;
    }
  }
}
.el-icon-close {
  width: 24px !important;
  height: 24px !important;
}
</style>
