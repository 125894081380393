

import axios from "axios";
/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(localStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}
/**
 * 获取当前用户id
 */
export function getUserId() {
  return JSON.parse(localStorage.getItem('predicthealthUserInfo')).userId || 0
}
/**
 * 获取当前登录表的iD
 */
export function getQueryId() {
  return JSON.parse(localStorage.getItem('predicthealthUserInfo')).id
}
/**
 * 获取当前登录表名称
 */
export function getName() {
  return JSON.parse(localStorage.getItem('predicthealthUserInfo')).name
}
/**
 * 获取当前登录角色
 */
export function getUserRoleName() {
  let userInfo = JSON.parse(localStorage.getItem('predicthealthUserInfo'))
  let roleName = ''
  if (
    userInfo.sysRoleList &&
    userInfo.sysRoleList.length > 0 &&
    userInfo.sysRoleList[0].roleName) {
    roleName = userInfo.sysRoleList[0].roleName;
  } else roleName = userInfo.roleName;
  return roleName
}
/**
 * 查询条件多的处理及过滤，现有的过滤（省市区，开始时间结束时间，）
 * @param {*} data 查询相关信息
 */
export function processingDataQuery(data) {
  if (this.queryRegion && this.queryRegion.length > 0) {
  } else {
    data.province = '';
    data.city = '';
    data.area = '';
  }
  if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
    let timeArr = this.startTimeAndEndTime;
    data.startTime = timeArr[0];
    data.endTime = timeArr[1];
  } else {
    data.startTime = "";
    data.endTime = "";
  }
}
/* 查询所有省份 */
export function getProvince(node, resolve) {
  axios.get(`admin/sys-province/getList`).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      let list = [];
      data.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
          name: item.name,
          leaf: node.level >= 2,
        });
      });
      resolve(list);
    }
  });
}
/* 根据省份查询所有市 */
export function getCity(node, resolve) {
  axios
    .get(`admin/sys-city/getByProvinceId/${node.value}`)
    .then((res) => {
      if (res.data.code == 200) {
        sessionStorage.setItem("province", node.label)
        let data = res.data.data;
        let list = [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            label: item.name,
            name: item.name,
            leaf: node.level >= 2,
          });
        });
        resolve(list);
      }
    });
}
/* 根据市查询所有区/县/乡 */
export function getArea(node, resolve, name) {
  axios.get(`admin/sys-area/getByCityId/${node.value}`).then((res) => {
    if (res.data.code == 200) {
      sessionStorage.setItem("city", node.label)
      let data = res.data.data;
      let list = [];
      if (name == '全部') list.push({
        value: 0,
        label: '全部',
        name: '全部',
        leaf: node.level >= 2,
      });
      data.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
          name: item.name,
          leaf: node.level >= 2,
        });
      });
      resolve(list);
    }
  });
}
/**
 * 获取序号
 */
export function getSeq(index, pageNum, pageSize) {
  let seq = (pageNum - 1) * pageSize + index + 1
  return seq
}